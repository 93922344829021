@charset "utf-8";

$siteHeaderSize: 72px;
$baseSpace: 75px;

$ts: all 0.2s linear;

$baseFont: "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体",
  YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$notoFont: "Noto Sans JP", sans-serif;

$tp: transparent;
$baseColor: #0a4a90;
$blueColor: #0f66c5;
$lightBlueColor: #f3f6f9;
$vividBlueColor: #008de2;
$orangeColor: #f8b427;
$lightGrayColor: #f0f0f0;
$bkColor: #000;

// **************************************************
//	mediaQuery
// **************************************************
$greaterBreakpoints: (
  xs: "screen and (min-width: 401px)",
  sm: "screen and (min-width: 601px)",
  md: "screen and (min-width: 769px)",
  lg: "screen and (min-width: 1025px)",
  xl: "screen and (min-width: 1281px)",
) !default;

$lessBreakpoints: (
  xs: "screen and (max-width: 400px)",
  sm: "screen and (max-width: 600px)",
  md: "screen and (max-width: 768px)",
  lg: "screen and (max-width: 1024px)",
  xl: "screen and (max-width: 1280px)",
) !default;

@mixin greaterMediaQuery($breakpoint: lg) {
  @media #{map-get($greaterBreakpoints, $breakpoint)} {
    @content;
  }
}

@mixin lessMediaQuery($breakpoint: lg) {
  @media #{map-get($lessBreakpoints, $breakpoint)} {
    @content;
  }
}

@mixin greaterMediaQueryFree($w) {
  @media screen and (min-width: $w) {
    @content;
  }
}

@mixin lessMediaQueryFree($w) {
  @media screen and (max-width: $w) {
    @content;
  }
}

// **************************************************
//	mixin
// **************************************************
@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
} // Firefox

@mixin ie11 {
  #{unquote('_:-ms-input-placeholder, :root')} {
    @content;
  }
} // IE11

@mixin beforeAfter($w, $h) {
  content: "";
  width: $w;
  height: $h;
}

@mixin appearance($type: none) {
  -webkit-appearance: $type;
  -moz-appearance: $type;
  appearance: $type;
}

@mixin boxShadow($move, $size, $color, $concentration) {
  box-shadow: 0 $move $size rgba($color, $concentration);
}

@import "const";

@include lessMediaQuery() {
  * .pc {
    display: none;
  }
}

@include greaterMediaQuery() {
  * .sp {
    display: none;
  }
}

body {
  font-family: $baseFont;
  font-size: 14px;
  color: $bkColor;
  line-height: 1.75;
  letter-spacing: 0.05em;
  font-weight: 500;
}

main {
  padding-top: $siteHeaderSize;
  @include lessMediaQuery() {
    padding-top: 70px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.xlTx {
  margin-bottom: 2em;
  font-size: 41px;
}

.lgTx {
  margin-bottom: 2em;
  font-size: 30px;
}

.mdTx {
  margin-bottom: 2em;
  font-size: 26px;
}

.smTx {
  margin-bottom: 1em;
  font-size: 22px;
}

.xsTx {
  margin-bottom: 1em;
  font-size: 18px;
}

.center {
  text-align: center;
}

.fz18 {
  font-size: 18px;
}

a {
  transition: $ts;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

table {
  width: 100%;
}

th,
td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
}

th {
  background: #eee;
}

.flex {
  display: flex;
}

.flexHalf {
  @include lessMediaQuery() {
    flex-direction: column;
  }
  > * {
    flex: 1;
    &:first-child {
      margin-right: 60px;
      @include lessMediaQuery() {
        margin: 0 0 30px 0;
      }
    }
  }
  img {
    height: auto;
  }
}

.centerImg {
  margin: 0 auto;
  height: auto;
}

.centerBox {
  text-align: center;
}

.inner {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 0;
  position: relative;
  @include lessMediaQuery() {
    max-width: 90%;
    min-width: 90%;
  }
}

.mediaTx {
  flex: 1;
}

.bdrs {
  border-radius: 1000px;
}

.bdBaseColor {
  background: #fff;
  border: 2px solid $baseColor;
  color: $baseColor;
}

.bgBaseColor {
  background: $baseColor;
  color: #fff;
}

.secTtlBigEn {
  font-size: 20px;
  color: $baseColor;
  text-align: center;
  margin-bottom: 3em;
  .en {
    font-size: 41px;
    display: block;
    letter-spacing: 0.15em;
  }
}

.blue {
  color: $baseColor;
}

.pageHeader {
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.pageHeaderTtl {
  padding: 15px 0;
  font-size: 41px;
  color: $baseColor;
  text-align: center;
  letter-spacing: 0.1em;
  .en {
    font-size: 14px;
    display: block;
    letter-spacing: 0.05em;
  }
}

.breadcrumbs {
  margin-top: 15px;
  * {
    color: #636363;
  }
  .flex {
    align-items: center;
    flex-wrap: wrap;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
    span {
      color: $baseColor;
    }
  }
  .arrow {
    margin: 0 6px;
    font-size: 13px;
    position: relative;
    top: -2px;
  }
  .page-template-page &,
  .serviceMain & {
    span {
      &:nth-child(3) {
        a {
          pointer-events: none;
          span {
            color: #636363;
          }
        }
      }
    }
  }
}

.pageContents {
  > * {
    margin-top: $baseSpace;
    @include lessMediaQuery() {
      margin-top: $baseSpace / 2;
    }
  }
}

.rectangularTtl {
  margin-bottom: $baseSpace / 2;
  font-size: 24px;
  background: $lightBlueColor;
  padding: 0.4em 0.8em;
  letter-spacing: 0.1em;
  @include lessMediaQuery() {
    padding: 0.6em 0.8em;
    line-height: 1.2;
  }
}

.borderBottomTtl {
  letter-spacing: 0.1em;
  margin-bottom: $baseSpace / 2;
  padding-bottom: 10px;
  font-size: 24px;
  border-bottom: 2px solid $baseColor;
  font-weight: 700;
}

.ttlBlueTable {
  $w: 180px;
  border-top: 1px solid #ccc;
  @include lessMediaQuery() {
    border-top: none;
  }
  dl {
    position: relative;
    border-bottom: 1px solid #ccc;
    @include lessMediaQuery() {
      flex-direction: column;
      border-bottom: none;
    }
    &::before {
      @include beforeAfter($w, 100%);
      position: absolute;
      top: 0;
      left: 0;
      background: $lightBlueColor;
      @include lessMediaQuery() {
        display: none;
      }
    }
  }
  dt {
    padding: 20px 0;
    width: $w;
    position: relative;
    color: $baseColor;
    text-align: center;
    font-weight: 700;
    @include lessMediaQuery() {
      padding: 15px 0;
      background: $lightBlueColor;
      width: 100%;
    }
  }
  dd {
    padding: 20px;
    flex: 1;
    @include lessMediaQuery() {
      padding: 15px;
      width: 100%;
    }
  }
  .iframeWrap {
    overflow: hidden;
    height: 300px;
    width: 100%;
    margin-top: 15px;
    @include lessMediaQuery() {
      height: 165px;
    }
  }
  iframe {
    width: 600px;
    height: 800px;
    margin-top: -240px;
    @include lessMediaQuery() {
      width: 100%;
      height: 600px;
      margin-top: -210px;
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.timelineTable {
  $w: 140px;
  $spW: 100px;
  position: relative;
  padding: 10px 0;
  &::before {
    @include beforeAfter(1px, 100%);
    position: absolute;
    top: 0;
    left: calc(#{$w} + 50px);
    background: #ccc;
    @include lessMediaQuery() {
      left: calc(#{$spW} + 25px);
    }
  }
  dl {
    align-items: center;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 50px;
      @include lessMediaQuery() {
        margin-bottom: 30px;
      }
    }
  }
  dt {
    width: $w;
    text-align: right;
    margin-right: 100px;
    font-weight: 700;
    color: $baseColor;
    position: relative;
    @include lessMediaQuery() {
      width: $spW;
      margin-right: 50px;
    }
    &::before {
      @include beforeAfter(10px, 10px);
      border-radius: 10px;
      background: $baseColor;
      position: absolute;
      top: calc(50% - 5px);
      left: calc(#{$w} + 46px);
      @include lessMediaQuery() {
        left: calc(#{$spW} + 20px);
      }
    }
  }
  dd {
    flex: 1;
  }
}

.txLink {
  color: $vividBlueColor;
  &:hover {
    text-decoration: underline;
  }
}

.pageMenus {
  background: $lightBlueColor;
  margin: $baseSpace / 1.5 0;
  padding: 45px 0;
  ul {
    flex-wrap: wrap;
    margin: 0 -15px;
    @include lessMediaQuery() {
      flex-direction: column;
      margin-top: -20px;
      align-items: center;
    }
  }
  li {
    width: calc(100% / 3);
    padding: 0 15px;
    @include lessMediaQuery() {
      width: 100%;
      max-width: 450px;
      margin-top: 20px;
    }
    &:nth-child(n + 4) {
      margin-top: 30px;
      @include lessMediaQuery() {
        margin-top: 20px;
      }
    }
  }
  @mixin styleChange {
    background: $baseColor;
    color: #fff;
    &::before {
      border-top-color: #fff;
      border-right-color: #fff;
    }
  }
  a {
    font-size: 18px;
    padding: 1.25em 30px 1.25em 10px;
    display: block;
    text-align: center;
    background: #fff;
    border: 2px solid $baseColor;
    color: $baseColor;
    position: relative;
    &:hover {
      @include styleChange;
    }
    &::before {
      @include beforeAfter(10px, 10px);
      border-top: 2px solid $baseColor;
      border-right: 2px solid $baseColor;
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
  span {
    display: block;
    font-size: 14px;
  }
  .current-menu-item {
    a {
      @include styleChange;
    }
  }
}

.mainColumn {
  flex: 1;
  margin-right: 50px;
}

.sidebar {
  width: 250px;
  ul {
    border-top: 2px solid #ccc;
  }
  a {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }
  .mediaImg {
    width: 60px;
  }
  .mediaTx {
    margin-left: 20px;
  }
  time {
    font-size: 12px;
    color: $orangeColor;
  }
  h6 {
    font-size: 14px;
  }
}

.sidebarTtl {
  font-size: 17px;
  margin-bottom: 15px;
}

#wpPagenavi {
  margin: $baseSpace 0;
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    * {
      border: none;
      width: 36px;
      height: 36px;
      display: block;
      margin: 0 3px;
      text-align: center;
      line-height: 36px;
      padding: 0;
    }
  }
  a {
    &:hover {
      background: $baseColor;
      color: #fff;
    }
  }
  .current {
    background: $baseColor;
    color: #fff;
  }
}

.emphasisTtl {
  background: url(../img/stripe.png);
  background-size: 8px;
  padding: 0.5em 0.5em 0.5em 90px;
  font-size: 30px;
  font-weight: 700;
  color: #222;
  position: relative;
  margin-bottom: 40px;
  &::before {
    @include beforeAfter(50px, 50px);
    background: url(../img/icon_warning_min.png) no-repeat center;
    background-size: 100% auto;
    position: absolute;
    left: 20px;
    top: calc(50% - 25px);
  }
}

.iconTtl {
  font-size: 24px;
  font-weight: 700;
  padding: 18px 0 18px 80px;
  margin-bottom: 1em;
  background-position: left center;
  background-size: 55px auto;
  background-repeat: no-repeat;
  @include lessMediaQuery() {
    line-height: 1.2;
  }
}

.right {
  text-align: right;
}

.scroll {
  img {
    height: auto;
  }
  @include lessMediaQuery() {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    table {
      table-layout: fixed;
    }
    th,
    td {
      width: 180px;
    }
  }
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  margin: 0 auto;
}

.rectangleBtn {
  text-align: center;
  a {
    padding: 30px 80px;
    display: inline-block;
    background: #37357d;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
  }
  &.toContact {
    a {
      background-repeat: no-repeat;
      background-color: #0867cb;
      background-image: url(../img/mail_icon.png);
      background-position: left 100px center;
      background-size: 44px auto;
      padding: 30px 100px 30px 155px;
      @include lessMediaQuery() {
        padding: 30px 0 30px 40px;
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        background-position: left 15px center;
        background-size: 36px auto;
      }
    }
  }
}

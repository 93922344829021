@import "const";

// **************************************************
//	spSiteHeader
// **************************************************
@include lessMediaQuery() {
  #spSiteHeader {
    width: 100%;
    height: 70px;
    padding: 0 5%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #fff;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: block;
      line-height: 1;
      align-items: center;
      img {
        width: 125px;
        display: block;
      }
    }
    .parentNav {
      align-items: center;
    }
    .mainLink {
      font-size: 12px;
      flex-direction: column;
      align-items: center;
    }
    i {
      width: 20px;
      height: 22px;
      line-height: 1;
    }
    .login {
      color: $baseColor;
      margin-right: 10px;
      svg {
        fill: $baseColor;
      }
    }
    .shop {
      color: $orangeColor;
      svg {
        fill: $orangeColor;
      }
    }
    #spNav {
      margin-left: 20px;
      width: 20px;
      position: relative;
      display: block;
      height: 19px;
      background: $tp;
      border: none;
      border-top: 3px solid $baseColor;
      transition: $ts;
      padding: 0;
      &::before,
      &::after {
        @include beforeAfter(20px, 3px);
        background: $baseColor;
        position: absolute;
        right: 0;
        transition: $ts;
      }
      &::before {
        top: 5px;
      }
      &::after {
        bottom: 0;
      }
      &.active {
        border: none;
        &::before {
          transform: rotate(45deg);
          top: 6px;
        }
        &::after {
          transform: rotate(-45deg);
          bottom: 10px;
        }
      }
    }
    #search {
      padding: 20px;
      align-items: center;
      .searchBox {
        width: 100%;
        position: relative;
        margin: 0 auto;
      }
      .searchTx {
        width: 100%;
        border: none;
        background: #fff;
        border-radius: 500px;
        padding: 10px 20px;
      }
      .searchBtn {
        width: 16px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    .dropdown {
      border-top: 1px solid #eee;
      background: $lightBlueColor;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100vh;
      padding-bottom: 210px;
      border-bottom: 10px solid $baseColor;
      display: none;
      overflow: scroll;
    }
    .mainMenu {
      margin-bottom: 30px;
      a {
        display: block;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .mainTtl {
      padding: 10px 20px;
      font-weight: 700;
      font-size: 18px;
      border-bottom: 1px solid #a5a5a5;
      position: relative;
      &::before {
        @include beforeAfter(20px, 2px);
        background: #222;
        position: absolute;
        top: 27px;
        right: 20px;
      }
      &::after {
        @include beforeAfter(2px, 20px);
        background: #222;
        position: absolute;
        top: 18px;
        right: 29px;
        transition: $ts;
      }
      &.active {
        &::after {
          opacity: 0;
        }
      }
    }
    .subMenu {
      display: none;
      margin: 20px 0;
      .ttl {
        font-size: 16px;
        font-weight: 700;
        padding-left: 20px;
        font-size: 17px;
      }
      li {
        + .ttl {
          margin-top: 15px;
        }
      }
      a {
        font-size: 16px;
        padding: 4px 20px 4px 40px;
        position: relative;
        &::before {
          @include beforeAfter(6px, 6px);
          border-top: 2px solid #222;
          border-right: 2px solid #222;
          transform: rotate(45deg);
          position: absolute;
          left: 20px;
          top: calc(50% - 3px);
          transition: $ts;
        }
      }
    }
    .contact {
      display: block;
      padding: 10px 60px;
      font-size: 20px;
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
  }
  #siteHeader {
    display: none;
  }
}

// **************************************************
//	siteHeader
// **************************************************
@include greaterMediaQuery() {
  #spSiteHeader {
    display: none;
  }
  #siteHeader {
    width: 100%;
    height: $siteHeaderSize;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    border-bottom: 1px solid #fff;
    box-shadow: 0 0 20px rgba(#000, 0.06);
    align-items: center;
    @include boxShadow(1px, 1px, #000, 0.1);
    .logo {
      height: $siteHeaderSize;
      width: 125px;
      margin: 0 auto 0 20px;
      align-items: center;
      a {
        &:hover {
          opacity: 0.7;
        }
      }
    }
    #search {
      align-items: center;
      margin-right: 15px;
      .searchBox {
        position: relative;
      }
      .searchTx {
        border: none;
        background: #f3f3f3;
        border-radius: 500px;
        padding: 10px 20px;
      }
      .searchBtn {
        width: 16px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
    nav {
      height: $siteHeaderSize;
    }
    a {
      letter-spacing: 0;
    }
    .mainLink {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    i {
      width: 20px;
      margin-bottom: 5px;
    }
    svg {
      fill: #fff;
    }
    .normal {
      position: relative;
      border-left: 1px solid #f3f3f3;
      transition: $ts;
      &:hover {
        background: $baseColor;
        > a {
          color: #fff;
        }
      }
      > a {
        padding: 0 20px;
        font-size: 15px;
        font-weight: 700;
      }
      &:hover {
        .subMenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .service {
      .subMenu {
        width: 480px;
      }
    }
    .product {
      .subMenu {
        width: 630px;
        .flex {
          justify-content: space-between;
        }
      }
    }
    .contact,
    .login,
    .shop {
      a {
        flex-direction: column;
        color: #fff;
        font-size: 12px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .contact {
      a {
        background: $blueColor;
        padding: 0 35px;
      }
    }
    .login {
      a {
        background: $baseColor;
        padding: 0 10px;
      }
    }
    .shop {
      a {
        background: $orangeColor;
        padding: 0 20px;
      }
    }
    .subMenu {
      left: 0;
      width: 240px;
      padding: 10px;
      background: rgba(#fff, 0.9);
      visibility: hidden;
      position: absolute;
      transition: $ts;
      opacity: 0;
      z-index: 99999;
      @include boxShadow(3px, 7px, #000, 0.2);
      .ttl {
        color: $baseColor;
        font-size: 16px;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        &::before {
          @include beforeAfter(90%, 2px);
          background: $baseColor;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      a {
        font-size: 14px;
        display: block;
        padding: 10px 10px 10px 30px;
        color: $baseColor;
        position: relative;
        &:hover {
          &::before {
            left: 13px;
          }
        }
        &::before {
          @include beforeAfter(6px, 6px);
          border-top: 2px solid $baseColor;
          border-right: 2px solid $baseColor;
          transform: rotate(45deg);
          position: absolute;
          left: 8px;
          top: calc(50% - 4px);
          transition: $ts;
        }
      }
    }
  }
  @include ie11 {
    #siteHeader .logo img {
      max-width: 125px;
    }
    #siteHeader #search .searchTx {
      max-width: 180px;
    }
    #siteHeader .contact a,
    #siteHeader .shop a {
      padding: 0 10px;
    }
    #siteHeader i {
      height: 30px;
    }
  }
}

@include firefox {
  @include greaterMediaQuery() {
    #siteHeader #search .searchTx {
      max-width: 180px;
    }
  }
}

// **************************************************
//	siteFooter
// **************************************************
#siteFooter {
  background: $lightGrayColor;
  padding-top: 30px;
  .inner {
    @include lessMediaQuery() {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
  nav {
    @include lessMediaQuery() {
      flex-direction: column;
    }
  }
  dl {
    margin-right: 20px;
    @include lessMediaQuery() {
      margin-right: 0;
    }
  }
  a {
    color: #404040;
    &:hover {
      color: $baseColor;
    }
  }
  dt {
    color: #404040;
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid #a5a5a5;
    margin-bottom: 5px;
    @include lessMediaQuery() {
      padding: 10px 20px;
      font-weight: 700;
      font-size: 18px;
      border-bottom: 1px solid #a5a5a5;
      position: relative;
      &::before {
        @include beforeAfter(20px, 2px);
        background: #222;
        position: absolute;
        top: 27px;
        right: 20px;
      }
      &::after {
        @include beforeAfter(2px, 20px);
        background: #222;
        position: absolute;
        top: 18px;
        right: 29px;
        transition: $ts;
      }
      &.active {
        &::after {
          opacity: 0;
        }
      }
    }
  }
  dd {
    @include lessMediaQuery() {
      display: none;
      margin: 20px 0;
    }
    a {
      font-size: 10px;
      position: relative;
      display: block;
      padding-left: 10px;
      @include lessMediaQuery() {
        font-size: 16px;
        padding: 4px 20px 4px 40px;
      }
      &:hover {
        &::before {
          border-top-color: $baseColor;
          border-right-color: $baseColor;
        }
      }
      &::before {
        @include beforeAfter(3px, 3px);
        border-top: 1px solid #404040;
        border-right: 1px solid #404040;
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: calc(50% - 1px);
        transition: $ts;
        @include lessMediaQuery() {
          @include beforeAfter(6px, 6px);
          border-top: 2px solid #404040;
          border-right: 2px solid #404040;
          left: 20px;
          top: calc(50% - 3px);
        }
      }
    }
    &.flex {
      ul {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
  .otherMenu {
    margin-left: auto;
    width: 130px;
    @include lessMediaQuery() {
      margin: 40px auto 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    .contact {
      a {
        background: $baseColor;
      }
    }
    .shop {
      a {
        background: $orangeColor;
      }
    }
    .contact,
    .shop {
      @include lessMediaQuery() {
        width: 100%;
      }
      a {
        color: #fff;
        font-size: 10px;
        text-align: center;
        padding: 8px 0;
        @include lessMediaQuery() {
          font-size: 20px;
          width: 250px;
          margin: 0 auto;
        }
      }
    }
    .other {
      text-align: center;
      @include lessMediaQuery() {
        width: 160px;
        margin: 30px 10px 0;
      }
      span {
        font-size: 10px;
        font-family: $notoFont;
        letter-spacing: 0;
        transform: scale(0.9);
        transform-origin: center;
        display: block;
        width: 120%;
        margin: 2px 0 0 -10%;
        @include lessMediaQuery() {
          font-size: 12px;
        }
      }
    }
  }
  small {
    font-family: $notoFont;
    font-size: 10px;
    background: $baseColor;
    color: #fff;
    text-align: center;
    margin-top: $baseSpace;
    @include lessMediaQuery() {
      margin-top: 20px;
      padding: 4px 0;
    }
  }
}

@import "const";

// index
// **************************************************
//	hero
// **************************************************
#hero {
  width: 100%;
  overflow: hidden;
  .slick-slide {
    margin: 0 15px;
    @include lessMediaQuery() {
      margin: 0;
    }
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .slick-arrow {
    z-index: 9999;
    width: 40px;
    height: 40px;
    &::before {
      @include beforeAfter(100%, 100%);
      border-radius: 100px;
      background: $baseColor;
      display: block;
      opacity: 1;
    }
    &::after {
      @include beforeAfter(12px, 12px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: calc(50% - 6px);
    }
  }
  .slick-prev {
    left: 3%;
    &::after {
      left: 16px;
      transform: rotate(-135deg);
    }
  }
  .slick-next {
    right: 3%;
    &::after {
      right: 16px;
      transform: rotate(45deg);
    }
  }
}

// **************************************************
//	news
// **************************************************
#news {
  padding: 25px 0 50px;
  .inner {
    @include lessMediaQuery() {
      flex-direction: column;
      position: relative;
    }
  }
  a {
    &:hover {
      opacity: 0.7;
    }
  }
  h2 {
    font-size: 14px;
    color: $baseColor;
    font-weight: 700;
    margin-right: 30px;
  }
  dl {
    @include lessMediaQuery() {
      flex-direction: column;
      width: 100%;
    }
  }
  dt {
    margin-right: 15px;
  }
  dd {
    a {
      color: $baseColor;
      border-bottom: 1px solid $baseColor;
      padding-bottom: 2px;
    }
  }
  time {
    @include lessMediaQuery() {
      position: absolute;
      top: 0;
      left: 75px;
    }
  }
  .link {
    margin-left: auto;
    @include lessMediaQuery() {
      margin: 5px 0 0;
      text-align: right;
    }
  }
}

// **************************************************
//	newProduct
// **************************************************
#newProduct {
  .secTtlBigEn {
    margin-bottom: 2em;
  }
  a {
    flex-direction: column;
    align-items: center;
  }
  h3 {
    color: #666;
    margin: 0.25em 0 0.5em;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin-top: 10px;
  }
  .slick-arrow {
    z-index: 9999;
    width: 40px;
    height: 40px;
    top: 40%;
    &::before {
      @include beforeAfter(100%, 100%);
      border-radius: 100px;
      background: $baseColor;
      display: block;
      opacity: 1;
    }
    &::after {
      @include beforeAfter(12px, 12px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: calc(50% - 6px);
    }
  }
  .slick-prev {
    left: -20px;
    &::after {
      left: 16px;
      transform: rotate(-135deg);
    }
  }
  .slick-next {
    right: -20px;
    &::after {
      right: 16px;
      transform: rotate(45deg);
    }
  }
  .slick-slide {
    margin: 0 5px;
  }
}

// **************************************************
//	products
// **************************************************
#products {
  padding: $baseSpace 0;
  @include lessMediaQuery() {
    padding: 60px 0 0;
  }
  .inner {
    @include lessMediaQuery() {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .tabs {
    position: relative;
    input[name="tabItem"] {
      display: none;
    }
    .tabItem {
      cursor: pointer;
      transition: $ts;
    }
    .tabsBtn {
      justify-content: center;
      border-bottom: 1px solid $baseColor;
    }
    label {
      background: #f3f3f3;
      color: #888;
      font-size: 18px;
      font-weight: 700;
      padding: 25px 70px;
      margin: 0 8px;
      @include lessMediaQuery() {
        padding: 15px 10px;
        text-align: center;
        line-height: 1.2;
        margin: 0 3px;
      }
      &:hover {
        color: $baseColor;
        background: rgba($baseColor, 0.1);
      }
    }
    #tab01:checked ~ .tabsBtn [for="tab01"],
    #tab02:checked ~ .tabsBtn [for="tab02"],
    #tab03:checked ~ .tabsBtn [for="tab03"] {
      background: #fff;
      color: $baseColor;
      border-top: 5px solid $baseColor;
      border-right: 1px solid $baseColor;
      border-left: 1px solid $baseColor;
      position: relative;
      &::before {
        @include beforeAfter(100%, 3px);
        background: #fff;
        position: absolute;
        bottom: -3px;
        left: 0;
      }
    }
    .tabContent {
      @include greaterMediaQuery() {
        display: none;
      }
    }
    #tab01:checked ~ #tab01Content,
    #tab02:checked ~ #tab02Content,
    #tab03:checked ~ #tab03Content {
      display: block;
      animation: show 0.2s linear;
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  .parentCatsSp {
    @include greaterMediaQuery() {
      display: none;
    }
    .slick-slide {
      padding: 40px;
    }
    .slick-arrow {
      z-index: 9999;
      width: 40px;
      height: 40px;
      top: 36%;
      &::before {
        @include beforeAfter(100%, 100%);
        border-radius: 100px;
        background: $baseColor;
        display: block;
        opacity: 1;
      }
      &::after {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: calc(50% - 6px);
      }
    }
    .slick-prev {
      left: 3%;
      &::after {
        left: 16px;
        transform: rotate(-135deg);
      }
    }
    .slick-next {
      right: 3%;
      &::after {
        right: 16px;
        transform: rotate(45deg);
      }
    }
  }
  .parentCats {
    flex-wrap: wrap;
    justify-content: space-between;
    @include lessMediaQuery() {
      display: none;
    }
    > li {
      width: calc(50% - 30px);
      margin-top: 60px;
    }
  }
  .media {
    @include lessMediaQuery() {
      flex-direction: column;
      align-items: center;
    }
  }
  .mediaImg {
    width: 45%;
    @include lessMediaQuery() {
      width: 100%;
      max-width: 240px;
    }
  }
  .mediaTx {
    flex: 1;
    margin-left: 25px;
    @include lessMediaQuery() {
      margin: 30px 0 0;
    }
  }
  .lgTx {
    line-height: 1.2;
    color: $baseColor;
    margin-bottom: 0.5em;
  }
  .childCats {
    margin-top: 20px;
    flex-wrap: wrap;
    li {
      margin: 0 8px 8px 0;
    }
    a {
      padding: 0.5em 1em;
      font-size: 12px;
    }
  }
}

// **************************************************
//	service
// **************************************************
#service {
  width: 100%;
  overflow: hidden;
  padding: $baseSpace 0;
  background: $lightBlueColor;
  @include lessMediaQuery() {
    padding: $baseSpace / 1.5 0;
  }
  .serviceSlide {
    .slick-slide {
      transition: $ts;
      transform: scale(0.85);
      opacity: 0.5;
      img {
        width: 100%;
        height: auto;
      }
    }
    .slick-current {
      transform: scale(1);
      opacity: 1;
    }
    .slick-arrow {
      z-index: 9999;
      width: 40px;
      height: 40px;
      &::before {
        @include beforeAfter(100%, 100%);
        border-radius: 100px;
        background: $baseColor;
        display: block;
        opacity: 1;
      }
      &::after {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: calc(50% - 6px);
      }
    }
    .slick-prev {
      left: 3%;
      &::after {
        left: 16px;
        transform: rotate(-135deg);
      }
    }
    .slick-next {
      right: 3%;
      &::after {
        right: 16px;
        transform: rotate(45deg);
      }
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  .cardCat {
    background: $orangeColor;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    position: relative;
    padding: 0 8px;
    &::before {
      @include beforeAfter(0, 0);
      border: 20px solid $tp;
      border-top: 20px solid $orangeColor;
      transform: rotate(45deg);
      position: absolute;
      top: 8px;
      right: -20px;
    }
  }
  .cardImg {
    position: relative;
    top: -1px;
  }
  .cardTx {
    padding: 25px;
    background: $baseColor;
  }
  h3,
  .desc {
    color: #fff;
  }
  h3 {
    text-align: center;
    font-weight: 700;
    line-height: 1.3;
    font-size: 18px;
    margin-bottom: 1.5em;
  }
  .bdBaseColor {
    width: 178px;
    padding: 4px 0;
    margin: 50px auto 0;
    text-align: center;
    position: relative;
    &::before {
      @include beforeAfter(8px, 8px);
      border-top: 2px solid $baseColor;
      border-right: 2px solid $baseColor;
      transform: rotate(45deg);
      position: absolute;
      right: 22px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
}

#service01Contents {
  .rectangleBtnBlue {
    a {
      background: $baseColor;
    }
  }
  .rectangleBtn {
    margin-top: 40px;
    a {
      display: block;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

#service05Contents {
  .wp-image-665 {
    padding: 30px 50px;
    @include lessMediaQuery() {
      padding: 20px 10px;
    }
  }
  .txFlex {
    margin: 20px 0 60px;
    min-height: auto;
    dt {
      min-height: 72px;
      @include lessMediaQuery() {
        padding: 0;
      }
    }
  }
  .processing02 {
    .type {
      margin: 20px 0;
      @include lessMediaQuery() {
        flex-wrap: wrap;
      }
      li {
        flex: 1;
        border: 1px solid #ccc;
        @include lessMediaQuery() {
          width: 50%;
          flex: initial;
          &:nth-child(n + 3) {
            margin-top: 20px;
          }
          &:nth-child(odd) {
            border-left: 1px solid #ccc;
          }
        }
        + li {
          border-left: none;
        }
      }
      dt {
        padding: 15px 0;
        background: $lightBlueColor;
        color: $baseColor;
        font-weight: bold;
        text-align: center;
      }
      dd {
        padding: 30px 0;
      }
    }
  }
  .processingSample {
    margin-top: 40px;
    .media {
      align-items: center;
      padding: 0 40px 40px;
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
        padding: 0 0 40px;
      }
    }
    .mediaImg {
      @include lessMediaQuery() {
        max-width: 420px;
      }
    }
    .mediaTx {
      margin-left: 40px;
      @include lessMediaQuery() {
        margin: 20px 0 0 0;
        max-width: 420px;
      }
      p {
        + P {
          margin-top: 20px;
        }
      }
    }
    span {
      font-weight: bold;
    }
  }
  .table {
    &.pc {
      padding: 0 30px;
    }
    @include lessMediaQuery() {
      table {
        + table {
          margin-top: 30px;
        }
      }
    }
  }
  th {
    background: $lightBlueColor;
  }
  .bdbn {
    border-bottom: none;
  }
  .bdrn {
    border-right: none;
  }
  .bdtDashed {
    border-top: 1px dashed #ccc;
  }
  .bdbDashed {
    border-bottom: 1px dashed #ccc;
  }
  .bdrDashed {
    border-right: 1px dashed #ccc;
  }
  .bdlDashed {
    border-left: 1px dashed #ccc;
  }
  .img {
    border-left: none;
    @include lessMediaQuery() {
      border-top: none;
      border-left: 1px solid #ccc;
    }
  }
  .talignleft {
    text-align: left;
    padding-left: 20px;
  }
  .flow {
    margin-top: 60px;
    h6 {
      text-align: center;
      font-weight: bold;
      font-size: 19px;
    }
    ol {
      padding: 0 30px;
      margin-top: 30px;
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
        padding: 0;
      }
    }
    li {
      + li {
        margin-top: 25px;
        .mediaImg {
          &::before {
            @include beforeAfter(13px, 24px);
            background: url(../img/caret-right-solid.svg) no-repeat center;
            background-size: 100% auto;
            transform: rotate(90deg);
            position: absolute;
            left: calc(50% - 12px);
            top: -24px;
          }
        }
      }
    }
    .media {
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
      }
    }
    .mediaImg {
      width: 440px;
      align-items: center;
      background: $lightBlueColor;
      justify-content: space-between;
      padding-left: 40px;
      position: relative;
      @include lessMediaQuery() {
        width: 100%;
        max-width: 480px;
        padding-left: 15px;
      }
    }
    span {
      display: block;
    }
    .num {
      text-align: center;
      display: block;
      color: $baseColor;
      font-size: 24px;
    }
    .tx {
      font-weight: bold;
      font-size: 19px;
      color: $baseColor;
      line-height: 1.75;
      text-align: center;
    }
    .img {
      width: 200px;
      @include lessMediaQuery() {
        width: auto;
        img {
          width: auto;
          height: 105px;
        }
      }
    }
    .mediaTx {
      padding: 20px 0 0 30px;
      @include lessMediaQuery() {
        width: 100%;
        max-width: 480px;
        padding: 10px 0 0 0;
      }
    }
    .rectangleBtn {
      a {
        background-color: #0867cb;
        background-image: url(../img/mail_icon.png);
        background-position: left 100px center;
        background-size: 44px auto;
        padding: 30px 100px 30px 155px;
        @include lessMediaQuery() {
          padding: 30px 0 30px 40px;
          width: 100%;
          max-width: 420px;
          margin: 60px auto 0;
          background-position: left 15px center;
          background-size: 36px auto;
        }
      }
    }
  }
}

// **************************************************
//	contact
// **************************************************
#contact {
  padding: $baseSpace 0;
  @include lessMediaQuery() {
    padding: $baseSpace / 1.5 0;
  }
  .contactList {
    justify-content: center;
    @include lessMediaQuery() {
      flex-direction: column;
      align-items: center;
    }
  }
  li {
    width: 200px;
    height: 200px;
    border: 3px solid $baseColor;
    margin: 0 35px;
    padding: 20px 0;
    text-align: center;
    @include lessMediaQuery() {
      width: 240px;
      height: 240px;
      padding: 30px 0 0;
      margin: 0;
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
  i {
    width: 40px;
    display: block;
    margin: 0 auto;
    @include lessMediaQuery() {
      width: 50px;
    }
  }
  svg {
    fill: #4c4c4c;
  }
  a {
    &:hover {
      opacity: 0.7;
    }
  }
  a[href^="tel:"] {
    color: $baseColor;
    font-weight: 700;
    font-size: 22px;
    font-family: $notoFont;
    display: inline-block;
    margin: 10px 0;
    @include lessMediaQuery() {
      font-size: 26px;
    }
  }
  .btn {
    display: inline-block;
    font-size: 11px;
    line-height: 1.2;
    padding: 1em 3em;
    margin: 20px 0;
    @include lessMediaQuery() {
      font-size: 14px;
    }
  }
  p {
    line-height: 1.5;
    font-size: 12px;
    letter-spacing: 0;
    @include lessMediaQuery() {
      font-size: 14px;
    }
  }
}

// page
// **************************************************
//	about_magtec
// **************************************************
.aboutMagtecHeader {
  background-image: url(../img/header_about_magtec_min.jpg);
  @include lessMediaQuery() {
    background-image: url(../img/header_about_magtec_min_sp.jpg);
  }
}

#overviewContents {
  .simotecGroup {
    .ttlBlueTable {
      margin-top: 15px;
      dl {
        &::before {
          width: 200px;
          @include lessMediaQuery() {
            display: none;
          }
        }
      }
      dt {
        width: 200px;
        @include lessMediaQuery() {
          width: 100%;
        }
      }
    }
  }
}

#csrContents {
  .inner {
    > section {
      margin-top: $baseSpace;
      section {
        margin-top: 40px;
      }
    }
    .csrContentsSystem {
      margin-top: 40px;
      h5 {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 25px;
      }
      ul {
        justify-content: center;
        margin-bottom: 20px;
        @include lessMediaQuery() {
          flex-direction: column;
          align-items: center;
        }
      }
      li {
        margin: 0 15px;
        width: 200px;
        height: 200px;
        border-radius: 200px;
        text-align: center;
        padding-top: 20px;
        @include lessMediaQuery() {
          margin: 0;
          + li {
            margin-top: 15px;
          }
        }
        &:first-child {
          background: #f3f6f9;
        }
        &:nth-child(2) {
          background: #eefcf8;
        }
        &:last-child {
          background: #fafaec;
        }
        * {
          font-weight: bold;
          color: $baseColor;
        }
        dt {
          font-size: 30px;
        }
      }
    }
    .iso {
      .media {
        @include lessMediaQuery() {
          flex-direction: column;
          align-items: center;
        }
      }
      .mediaTx {
        margin-right: $baseSpace;
        @include lessMediaQuery() {
          margin-right: 0;
        }
      }
      .mediaImg {
        width: 250px;
        @include lessMediaQuery() {
          margin-top: 20px;
        }
      }
      h5 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      .philosophy {
        h5 {
          text-align: center;
        }
      }
      .management {
        img {
          margin: 30px auto 0;
          max-width: 725px;
          width: 100%;
          @include lessMediaQuery() {
            max-width: 360px;
          }
        }
      }
    }
    .environment {
      h5 {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
      p {
        &.blue {
          margin-top: 30px;
        }
      }
      .rule {
        .ttlBlueTable {
          margin-top: 20px;
          dl {
            border-right: 1px solid #ccc;
            border-left: 1px solid #ccc;
            @include lessMediaQuery() {
              border-right: none;
              border-left: none;
            }
            &::before {
              border-right: 1px solid #ccc;
            }
          }
        }
      }
    }
    .case {
      .flexHalf {
        border: 1px solid #ccc;
        @include lessMediaQuery() {
          border: none;
        }
      }
      dl {
        &:first-child {
          border-right: 1px solid #ccc;
          margin-right: 0;
          @include lessMediaQuery() {
            border: none;
            margin: 0;
          }
        }
      }
      dt {
        background: $lightBlueColor;
        color: $baseColor;
        text-align: center;
        font-weight: bold;
        padding: 20px 0;
      }
      dd {
        padding: 20px 40px;
        @include lessMediaQuery() {
          padding: 20px;
        }
      }
    }
  }
}

#recruitContents {
  .rectangleBtn {
    margin-top: 40px;
    a {
      padding: 30px 120px;
      background: $baseColor;
      @include lessMediaQuery() {
        padding: 30px 0;
        display: block;
        text-align: center;
        max-width: 420px;
        margin: 0 auto;
      }
    }
  }
}

// **************************************************
//	service
// **************************************************
.serviceMain {
  .serviceHeader {
    background-image: url(../img/header_service_min.jpg);
    @include lessMediaQuery() {
      background-position: center right;
    }
  }
  .orderLink {
    justify-content: space-between;
    @include lessMediaQuery() {
      flex-direction: column;
      align-items: center;
    }
    li {
      width: calc(100% / 3 - 15px);
      @include lessMediaQuery() {
        width: 100%;
        max-width: 420px;
        + li {
          margin-top: 20px;
        }
      }
    }
    a {
      display: block;
      color: #fff;
      background: $orangeColor;
      font-size: 18px;
      text-align: center;
      font-weight: 700;
      padding: 20px 0;
      line-height: 1.5;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .support {
    .txFlex {
      margin: 60px 0 30px;
      @include lessMediaQuery() {
        flex-direction: column;
        margin-bottom: 10px;
      }
      > dt {
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
        @include lessMediaQuery() {
          width: 100%;
          padding: 40px 0;
        }
      }
      > dd {
        flex: 1;
        background: $lightBlueColor;
        padding: 20px 25px;
      }
      dl {
        @include lessMediaQuery() {
          flex-direction: column;
        }
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
          @include lessMediaQuery() {
            width: 100%;
          }
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      @include lessMediaQuery() {
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }
      &:last-of-type {
        &::after {
          width: calc(100% / 3 - 20px);
          content: "";
        }
      }
      li {
        width: calc(100% / 3 - 20px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
          + li {
            margin-top: 20px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    .outro {
      margin-top: $baseSpace;
      @include lessMediaQuery() {
        margin-top: 60px;
      }
    }
    .rectangleBtn {
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        @include lessMediaQuery() {
          display: block;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
  }
  .order {
    .txFlex {
      margin: 60px 0 30px;
      @include lessMediaQuery() {
        flex-direction: column;
        margin-bottom: 10px;
      }
      > dt {
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
        @include lessMediaQuery() {
          width: 100%;
          padding: 40px 0;
        }
      }
      > dd {
        flex: 1;
        background: $lightBlueColor;
        padding: 20px 25px;
      }
      dl {
        @include lessMediaQuery() {
          flex-direction: column;
        }
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
          @include lessMediaQuery() {
            width: 100%;
          }
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      @include lessMediaQuery() {
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }
      &:first-of-type {
        &::after {
          width: calc(100% / 3 - 20px);
          content: "";
        }
      }
      li {
        width: calc(100% / 3 - 20px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
          + li {
            margin-top: 20px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    .wp-image-430 {
      width: 100%;
      max-width: 800px;
      margin: 50px auto $baseSpace;
      height: auto;
      @include lessMediaQuery() {
        margin: 60px 0;
      }
    }
    .rectangleBtn {
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        @include lessMediaQuery() {
          display: block;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
  }
  .processing {
    .txFlex {
      margin: 60px 0 30px;
      align-items: center;
      min-height: 120px;
      background: $lightBlueColor;
      @include lessMediaQuery() {
        flex-direction: column;
        margin-bottom: 10px;
      }
      > dt {
        text-align: center;
        min-height: 120px;
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
        @include lessMediaQuery() {
          width: 100%;
          padding: 40px 0;
        }
      }
      > dd {
        flex: 1;
        padding: 20px 25px;
      }
      dl {
        @include lessMediaQuery() {
          flex-direction: column;
        }
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
          @include lessMediaQuery() {
            width: 100%;
          }
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      @include lessMediaQuery() {
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }
      li {
        width: calc(100% / 3 - 20px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
          + li {
            margin-top: 20px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    .productListCard {
      flex-wrap: wrap;
      padding: 0 60px;
      justify-content: space-between;
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
      }
      li {
        margin-top: 30px;
        width: calc(50% - 30px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
        }
      }
      dt {
        font-weight: bold;
        color: $baseColor;
        margin-top: 15px;
      }
    }
    .productList {
      padding: 0 60px;
      @include lessMediaQuery() {
        margin-top: 30px;
        padding: 0;
      }
      li {
        margin-bottom: 30px;
      }
      span {
        line-height: 1.75;
      }
      .num {
        color: $baseColor;
        font-weight: bold;
        display: block;
        @include lessMediaQuery() {
          padding-left: 1.8em;
          text-indent: -1.8em;
        }
      }
      .cont {
        padding-left: 1.8em;
        margin-top: 5px;
        display: block;
      }
      > p {
        font-weight: bold;
        margin-bottom: 40px;
        color: #2c2c2c;
      }
    }
    .rectangleBtn {
      margin-top: 60px;
      @include lessMediaQuery() {
        margin-top: 30px;
      }
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
        @include lessMediaQuery() {
          display: block;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
    .img {
      justify-content: center;
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
      }
      .img01 {
        width: 310px;
        margin-right: 30px;
        @include lessMediaQuery() {
          width: 90%;
          max-width: 310px;
          margin: 20px 0 0;
        }
      }
      .img02 {
        width: 655px;
        @include lessMediaQuery() {
          width: 100%;
        }
      }
      img {
        height: auto;
      }
    }
  }
  .magneticApplicationProducts {
    .txFlex {
      margin: 60px 0 30px;
      background: $lightBlueColor;
      @include lessMediaQuery() {
        flex-direction: column;
        margin-bottom: 10px;
      }
      > dt {
        text-align: center;
        width: 250px;
        background: #e8edf3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $baseColor;
        font-size: 19px;
        font-weight: 700;
        @include lessMediaQuery() {
          width: 100%;
          padding: 40px 0;
        }
      }
      > dd {
        flex: 1;
        padding: 20px 25px;
      }
      dl {
        @include lessMediaQuery() {
          flex-direction: column;
        }
        &:first-of-type {
          margin-top: 20px;
        }
        dt {
          color: $baseColor;
          width: 220px;
          @include lessMediaQuery() {
            width: 100%;
          }
        }
        dd {
          flex: 1;
        }
      }
    }
    .imgFlex {
      justify-content: space-between;
      @include lessMediaQuery() {
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }
      li {
        width: calc(100% / 3 - 20px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
          + li {
            margin-top: 20px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    .rectangleBtn {
      margin-top: 60px;
      @include lessMediaQuery() {
        margin-top: 30px;
      }
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
        @include lessMediaQuery() {
          display: block;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
    .flow {
      margin-top: $baseSpace;
      h4 {
        text-align: center;
        font-weight: bold;
        font-size: 19px;
      }
      ol {
        margin-top: 30px;
        @include lessMediaQuery() {
          flex-direction: column;
          align-items: center;
        }
      }
      li {
        background: $lightBlueColor;
        padding: 40px 0;
        flex-direction: column;
        flex: 1;
        align-items: center;
        position: relative;
        @include lessMediaQuery() {
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          max-width: 340px;
          width: 100%;
          padding: 20px 30px;
        }
        + li {
          margin-left: 20px;
          @include lessMediaQuery() {
            margin: 30px 0 0;
          }
          &::before {
            @include beforeAfter(13px, 24px);
            background: url(../img/caret-right-solid.svg) no-repeat center;
            background-size: 100% auto;
            position: absolute;
            top: calc(50% - 12px);
            left: -15px;
            @include lessMediaQuery() {
              transform: rotate(90deg);
              top: -30px;
              left: calc(50% - 7px);
            }
          }
        }
        p {
          text-align: center;
          color: $baseColor;
          font-weight: bold;
          font-size: 19px;
          @include lessMediaQuery() {
            width: 110px;
            text-align: center;
          }
        }
      }
      .num {
        text-align: center;
        display: block;
        color: $baseColor;
        font-size: 24px;
        @include lessMediaQuery() {
          width: 60px;
          text-align: left;
        }
      }
      .img {
        height: 40px;
        margin: 20px 0;
        @include lessMediaQuery() {
          width: 60px;
          height: auto;
          margin: 0;
          text-align: center;
          display: block;
        }
      }
      svg {
        height: 100%;
        display: inline-block;
        @include lessMediaQuery() {
          width: 34px;
          height: auto;
        }
      }
      .rectangleBtn {
        a {
          background-color: #0867cb;
          background-image: url(../img/mail_icon.png);
          background-position: left 100px center;
          background-size: 44px auto;
          padding: 30px 100px 30px 155px;
          @include lessMediaQuery() {
            padding: 30px 0 30px 40px;
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            background-position: left 15px center;
            background-size: 36px auto;
          }
        }
      }
    }
  }
  .prototypingProcessing {
    .imgFlex {
      margin: 30px 0 60px;
      justify-content: space-between;
      @include lessMediaQuery() {
        padding: 10px;
        flex-direction: column;
        align-items: center;
      }
      li {
        width: calc(100% / 3 - 20px);
        @include lessMediaQuery() {
          width: 100%;
          max-width: 360px;
          + li {
            margin-top: 20px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    section {
      section {
        margin-bottom: 40px;
        > p {
          padding: 0 40px;
          @include lessMediaQuery() {
            padding: 0 20px;
          }
        }
      }
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.75em;
      padding: 0.4em 40px;
      @include lessMediaQuery() {
        padding: 0.8em 0;
        line-height: 1.5;
        text-align: center;
      }
    }
    .rectangleBtn {
      margin-top: 60px;
      @include lessMediaQuery() {
        margin-top: 30px;
        padding: 0;
      }
      a {
        background-image: url(../img/blank_link.png);
        background-repeat: no-repeat;
        background-position: right 12px bottom 12px;
        background-size: 27px auto;
        background-color: $orangeColor;
        padding: 20px 180px;
        @include lessMediaQuery() {
          display: block;
          text-align: center;
          padding: 20px 0;
        }
      }
    }
  }
  @include greaterMediaQuery() {
    .pageMenus {
      li {
        width: 50%;
        margin-top: 0;
        &:nth-child(n + 3) {
          margin-top: 30px;
        }
      }
    }
  }
}

// **************************************************
//	guide
// **************************************************
.guideHeader {
  background-image: url(../img/header_guide_min.jpg);
  @include lessMediaQuery() {
    background-image: url(../img/header_guide_min_sp.jpg);
    background-position: center right;
  }
}

// **************************************************
//	faq
// **************************************************
#faqContents {
  .faqNav {
    > ul {
      @include lessMediaQuery() {
        flex-direction: column;
      }
    }
    li {
      flex: 1;
      flex-basis: 25%;
      &:not(:last-child) {
        margin-right: 30px;
        @include lessMediaQuery() {
          margin-right: 0;
        }
      }
      + li {
        @include lessMediaQuery() {
          margin-top: 10px;
        }
      }
    }
    a {
      background: $baseColor;
      color: #fff;
      text-align: center;
      position: relative;
      padding: 30px 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include lessMediaQuery() {
        padding: 15px 45px 15px 15px;
        justify-content: flex-start;
        text-align: left;
      }
      &::before {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(135deg);
        position: absolute;
        bottom: 15px;
        left: calc(50% - 7px);
        transition: $ts;
        @include lessMediaQuery() {
          left: auto;
          right: 15px;
          bottom: auto;
          top: calc(50% - 9px);
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      line-height: 1.2;
      font-size: 16px;
    }
  }
  .faq {
    section {
      padding-top: 40px;
      h4 {
        margin-bottom: 20px;
        @include lessMediaQuery() {
          font-size: 21px;
        }
      }
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      dl {
        border: 1px solid #ccc;
        position: relative;
        &::before {
          content: "Q";
          color: $baseColor;
          font-weight: 700;
          font-size: 21px;
          position: absolute;
          left: 30px;
          top: 20px;
          z-index: 1;
          @include lessMediaQuery() {
            top: 14px;
            left: 12px;
          }
        }
      }
      dt {
        background: $lightBlueColor;
        color: $baseColor;
        font-size: 16px;
        font-weight: 700;
        padding: 15px 60px 15px 80px;
        position: relative;
        cursor: pointer;
        transition: $ts;
        @include lessMediaQuery() {
          padding: 10px 40px;
        }
        &:hover {
          opacity: 0.7;
        }
        &::before {
          @include beforeAfter(20px, 2px);
          background: $baseColor;
          position: absolute;
          top: 27px;
          right: 20px;
          @include lessMediaQuery() {
            top: 22px;
            right: 10px;
          }
        }
        &::after {
          @include beforeAfter(2px, 20px);
          background: $baseColor;
          position: absolute;
          top: 18px;
          right: 29px;
          transition: $ts;
          @include lessMediaQuery() {
            top: 13px;
            right: 19px;
          }
        }
      }
      .active {
        &::after {
          opacity: 0;
        }
      }
      dd {
        padding: 20px 20px 20px 80px;
        display: none;
        @include lessMediaQuery() {
          padding: 15px 15px 15px 40px;
        }
      }
    }
  }
}

// **************************************************
//	technical
// **************************************************
#technicalHeader {
  background-image: url(../img/header_technical_min.jpg);
  @include lessMediaQuery() {
    background-image: url(../img/header_technical_min_sp.jpg);
    background-position: center right;
  }
}

#technicalContents {
  .inner {
    @include lessMediaQuery() {
      margin-top: 20px;
    }
  }
  .mainColumn {
    @include lessMediaQuery() {
      margin-right: 0;
    }
  }
  .postList {
    a {
      padding: 30px 0;
      &:hover {
        opacity: 0.7;
      }
    }
    li {
      border-bottom: 1px solid #ccc;
      &:first-child {
        a {
          padding-top: 0;
        }
      }
    }
    .mediaImg {
      width: 150px;
      @include lessMediaQuery() {
        width: 100px;
      }
    }
    .mediaTx {
      margin-left: 40px;
      @include lessMediaQuery() {
        margin-left: 20px;
      }
    }
    h3 {
      color: $baseColor;
      margin-bottom: 10px;
      font-size: 23px;
      @include lessMediaQuery() {
        font-size: 18px;
      }
    }
    .more {
      display: inline-block;
      padding: 0.3em 3em;
      margin-top: 20px;
      position: relative;
      @include lessMediaQuery() {
        padding: 0.5em 3em;
      }
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .sidebar {
    @include lessMediaQuery() {
      display: none;
    }
  }
}

.technicalEntryPost {
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  .entryHeader {
    margin-bottom: 30px;
    .lgTx {
      font-weight: 700;
      color: $baseColor;
      border-bottom: 2px solid #ccc;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }
    time {
      color: $orangeColor;
      font-weight: 700;
    }
  }
  .entry {
    > section {
      margin-top: 40px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      color: $baseColor;
      background: $lightBlueColor;
      padding: 10px 20px;
      margin-bottom: 1em;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
    img {
      height: auto;
    }
    .alignleft {
      margin: 0 40px 20px 0;
      @include lessMediaQuery() {
        float: none;
        margin: 0 auto;
      }
    }
  }
}

.previousNext {
  margin-bottom: $baseSpace;
  a {
    font-size: 18px;
    display: block;
    text-align: center;
    position: relative;
    padding: 20px 0;
  }
  ul {
    li {
      flex: 1;
      margin: 0 15px;
      @include lessMediaQuery() {
        margin: 0;
      }
    }
    a {
      border: 2px solid $baseColor;
      color: $baseColor;
      &:hover {
        background: $baseColor;
        color: #fff;
        &::before {
          border-top-color: #fff;
          border-right-color: #fff;
        }
      }
    }
  }
  .previous {
    a {
      @include lessMediaQuery() {
        border-top: 2px solid $baseColor;
        border-right: 1px solid $baseColor;
        border-bottom: 2px solid $baseColor;
        border-left: 2px solid $baseColor;
      }
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        transform: rotate(-135deg);
        position: absolute;
        left: 15px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .next {
    a {
      @include lessMediaQuery() {
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        border-bottom: 2px solid $baseColor;
        border-left: 1px solid $baseColor;
      }
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid $baseColor;
        border-right: 2px solid $baseColor;
        transform: rotate(45deg);
        position: absolute;
        right: 15px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .back {
    margin: 30px auto 0;
    width: 80%;
    max-width: 500px;
    @include lessMediaQuery() {
      width: 100%;
      margin-top: 15px;
    }
    &:hover {
      opacity: 0.7;
    }
    &::before {
      @include beforeAfter(8px, 8px);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      left: 15px;
      top: calc(50% - 4px);
      transition: $ts;
    }
  }
}

// **************************************************
//	product
// **************************************************
#productHeader {
  background-image: url(../img/header_products_min.jpg);
  @include lessMediaQuery() {
    background-image: url(../img/header_products_min_sp.jpg);
    background-position: center right;
  }
}

#productContents {
  padding-top: 60px;
  @include lessMediaQuery() {
    padding-top: 30px;
  }
  #newProduct {
    margin-top: 20px;
  }
  #products {
    margin-top: 0;
  }
  .productLink {
    background: $orangeColor;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    width: 80%;
    max-width: 500px;
    margin: 0 auto 60px;
    display: block;
    text-align: center;
    line-height: 1.4;
    padding: 20px 0;
    position: relative;
    &:hover {
      opacity: 0.7;
    }
    &::before {
      @include beforeAfter(27px, 27px);
      background: url(../img/blank.png) no-repeat center bottom;
      position: absolute;
      right: 12px;
      bottom: 12px;
      background-size: 27px auto;
    }
  }
}

.productEntryPost {
  margin-top: -50px;
  @include lessMediaQuery() {
    margin-top: -25px;
  }
  .entryHeader {
    h3 {
      text-align: center;
      font-size: 36px;
      color: $baseColor;
      letter-spacing: 0.1em;
      margin: 0.5em 0;
      line-height: 1.2;
    }
  }
  .entry {
    section {
      margin-bottom: 60px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin-bottom: 1em;
    }
    p {
      &:empty {
        display: none;
      }
      + p {
        margin-top: 2em;
      }
    }
    table {
      margin-bottom: 20px;
    }
    th,
    td {
      color: $baseColor;
    }
    th {
      font-weight: normal;
      background: $lightBlueColor;
    }
  }
  img {
    height: auto;
  }
}

.productTaxonomyDesc {
  align-items: center;
  @include lessMediaQuery() {
    flex-direction: column;
  }
  .tx {
    text-align: center;
    @include lessMediaQuery() {
      margin: 0 0 30px 0;
    }
  }
  h3 {
    font-size: 41px;
    letter-spacing: 0.1em;
    margin-bottom: 0.5em;
  }
}

.taxonomyEntryList {
  flex-wrap: wrap;
  margin: 0 -20px 40px;
  text-align: center;
  @include lessMediaQuery() {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }
  li {
    padding: 0 20px;
    width: calc(100% / 3);
    margin-bottom: 40px;
    @include lessMediaQuery() {
      width: 100%;
      max-width: 360px;
    }
  }
  a {
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  h4 {
    font-size: 24px;
    margin: 0.25em 0 0.5em;
  }
}

.postid-466,
.postid-469,
.postid-477 {
  .entryHeader {
    display: none;
  }
}

.postid-469 {
  .cards {
    @include lessMediaQuery() {
      flex-direction: column;
      align-items: center;
    }
  }
  .card {
    text-align: center;
    margin-bottom: 60px;
    @include lessMediaQuery() {
      max-width: 270px;
    }
    img {
      height: auto;
    }
  }
}

.postid-477 {
  .sec02_01 {
    .flexHalf {
      @include lessMediaQuery() {
        div {
          &:first-child {
            order: 2;
          }
          &:last-child {
            order: 1;
          }
        }
      }
    }
  }
}

// **************************************************
//	guide
// **************************************************
#productsContents {
  section {
    section {
      margin: 40px 0 80px;
    }
  }
  .warning {
    ul {
      @include lessMediaQuery() {
        flex-direction: column;
      }
    }
    li {
      background: $lightBlueColor;
      flex: 1;
      padding: 25px;
      flex-direction: column;
      align-items: center;
      &:not(:last-child) {
        margin-right: 30px;
        @include lessMediaQuery() {
          margin: 0 0 30px 0;
        }
      }
    }
    img {
      width: 150px;
      height: auto;
      margin-bottom: 30px;
    }
  }
  .attention {
    section {
      &:nth-of-type(1) {
        .iconTtl {
          background-image: url(../img/icon_attention01.png);
        }
      }
      &:nth-of-type(2) {
        .iconTtl {
          background-image: url(../img/icon_attention02.png);
        }
      }
      &:nth-of-type(3) {
        .iconTtl {
          background-image: url(../img/icon_attention03.png);
        }
      }
      &:nth-of-type(4) {
        .iconTtl {
          background-image: url(../img/icon_attention04.png);
        }
      }
      &:nth-of-type(5) {
        .iconTtl {
          background-image: url(../img/icon_attention05.png);
        }
      }
    }
    ol {
      margin-left: 80px;
      counter-reset: number;
      @include lessMediaQuery() {
        margin-left: 0;
      }
      > li {
        position: relative;
        padding-left: 40px;
        counter-increment: number;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        &::before {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background: $lightBlueColor;
          color: $baseColor;
          position: absolute;
          left: 0;
          top: 0;
          content: counter(number);
          text-align: center;
          font-weight: 700;
          line-height: 25px;
        }
      }
      ul {
        margin: 20px 0 0 40px;
        @include lessMediaQuery() {
          margin-left: 15px;
        }
      }
    }
  }
}

#aircargoContents {
  section {
    section {
      margin-top: 80px;
    }
  }
  .borderBottomTtl {
    font-weight: 700;
  }
  p {
    + p {
      margin-top: 30px;
    }
  }
}

#lineupContents {
  .lineupNav {
    margin-top: 40px;
    padding-bottom: 40px;
    > ul {
      @include lessMediaQuery() {
        flex-direction: column;
      }
    }
    li {
      flex: 1;
      flex-basis: 25%;
      &:not(:last-child) {
        margin-right: 10px;
        @include lessMediaQuery() {
          margin-right: 0;
        }
      }
      + li {
        @include lessMediaQuery() {
          margin-top: 10px;
        }
      }
    }
    a {
      background: $baseColor;
      color: #fff;
      position: relative;
      padding: 30px 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include lessMediaQuery() {
        padding: 15px 45px 15px 15px;
        justify-content: flex-start;
      }
      &::before {
        @include beforeAfter(12px, 12px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(135deg);
        position: absolute;
        bottom: 15px;
        left: calc(50% - 7px);
        transition: $ts;
        @include lessMediaQuery() {
          left: auto;
          right: 15px;
          bottom: auto;
          top: calc(50% - 9px);
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      line-height: 1.2;
      font-size: 16px;
    }
  }
  section {
    section {
      margin: 40px 0 80px;
    }
  }
  .borderBottomTtl {
    font-weight: 700;
  }
  h5 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 1em;
  }
  p {
    + p {
      margin-top: 20px;
    }
  }
  #lineup01 {
    .media {
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
      }
    }
    .mediaImg {
      width: 270px;
      img {
        height: auto;
      }
    }
    .mediaTx {
      flex: 1;
      margin-left: 60px;
      @include lessMediaQuery() {
        margin: 30px 0 0 0;
      }
    }
    ul {
      margin-top: 40px;
      @include lessMediaQuery() {
        flex-direction: column;
      }
    }
    li {
      flex-direction: column;
      flex: 1;
      &:not(:last-child) {
        margin-right: 30px;
        @include lessMediaQuery() {
          margin-right: 0;
        }
      }
      @include lessMediaQuery() {
        + li {
          margin-top: 50px;
        }
      }
      > p {
        margin-top: 25px;
      }
    }
    .img {
      height: 145px;
      border: 1px solid #ccc;
      text-align: center;
      padding-top: 12px;
      img {
        width: auto;
        height: 120px;
        margin: 0 auto;
      }
    }
  }
  #lineup02 {
    > section {
      &:nth-of-type(2) {
        .mediaTx {
          order: 2;
        }
        .mediaImg {
          order: 1;
        }
      }
    }
    .flexHalf {
      @include lessMediaQuery() {
        flex-direction: column;
      }
      > * {
        flex: 1;
        &:first-child {
          margin-right: 30px;
          @include lessMediaQuery() {
            margin-right: 0;
          }
        }
      }
      .mediaTx {
        margin-top: 20px;
      }
    }
    .tabs {
      margin-top: 40px;
      input[name="tabItem"] {
        display: none;
      }
      .tabItem {
        cursor: pointer;
        transition: $ts;
      }
      .tabsBtn {
        justify-content: space-between;
        @include lessMediaQuery() {
          flex-wrap: wrap;
        }
      }
      label {
        flex: 1;
        color: $baseColor;
        font-size: 16px;
        font-weight: 700;
        padding: 20px 0;
        border: 2px solid $baseColor;
        text-align: center;
        @include lessMediaQuery() {
          width: calc(50% - 5px);
          flex: initial;
          &:nth-child(n + 3) {
            margin-top: 10px;
          }
        }
        &:not(:last-child) {
          margin-right: 10px;
          @include lessMediaQuery() {
            margin-right: 0;
          }
        }
        &:hover {
          color: #fff;
          background: $baseColor;
        }
      }
      #tab01:checked ~ .tabsBtn [for="tab01"],
      #tab02:checked ~ .tabsBtn [for="tab02"],
      #tab03:checked ~ .tabsBtn [for="tab03"],
      #tab04:checked ~ .tabsBtn [for="tab04"],
      #tab05:checked ~ .tabsBtn [for="tab05"] {
        background: $baseColor;
        color: #fff;
      }
      .tabContent {
        display: none;
        > p {
          margin: 30px 0 15px;
        }
      }
      #tab01:checked ~ #tab01Content,
      #tab02:checked ~ #tab02Content,
      #tab03:checked ~ #tab03Content,
      #tab04:checked ~ #tab04Content,
      #tab05:checked ~ #tab05Content {
        display: block;
        animation: show 0.2s linear;
      }
      tr {
        &:nth-child(odd) {
          background: $lightBlueColor;
        }
      }
      .txLink {
        color: $baseColor;
      }
      th {
        font-size: 16px;
        color: #636363;
        line-height: 1.2;
        @include lessMediaQuery() {
          &:first-child {
            width: 300px;
          }
        }
      }
      td {
        padding: 15px;
        color: #636363;
      }
      .taLeft {
        text-align: left;
      }
    }
  }
  #lineup03 {
    img {
      width: 100%;
      max-width: 800px;
      height: auto;
      margin: 40px auto;
    }
  }
  #lineup04,
  #lineup05 {
    .scroll {
      margin: 40px 0 0;
    }
    th,
    td {
      padding: 15px 10px;
    }
    th {
      background: $lightBlueColor;
      color: $baseColor;
    }
    td {
      color: #636363;
      &:first-child {
        font-weight: 700;
      }
    }
  }
  #lineup05 {
    .scroll {
      margin-bottom: 40px;
    }
  }
  .right {
    @include lessMediaQuery() {
      text-align: left;
    }
  }
}

// **************************************************
//	search
// **************************************************
#searchHeader {
  background: $lightBlueColor;
}

#searchContents {
  .inner {
    margin-top: 25px;
  }
  .searchResults {
    color: $baseColor;
  }
  .parentheses {
    &:first-child {
      position: relative;
      top: -5px;
      left: -3px;
    }
  }
  .bold {
    font-size: 18px;
    font-weight: 700;
  }
  dl {
    padding: 40px 0;
    border-bottom: 2px solid #ddd;
    @include lessMediaQuery() {
      padding: 30px 0;
    }
  }
  dt {
    a {
      font-size: 24px;
      color: $baseColor;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  dd {
    line-height: 1.4;
    margin-top: 10px;
  }
}

// **************************************************
//	news
// **************************************************
#newsHeader {
  background-image: url(../img/header_news_min.jpg);
  @include lessMediaQuery() {
    background-image: url(../img/header_news_min_sp.jpg);
    background-position: center right;
  }
}

#newsContents {
  .inner {
    margin-top: 0;
  }
  .postList {
    li {
      border-bottom: 1px solid #ccc;
    }
    a {
      padding: 30px 0 35px;
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    time {
      color: $orangeColor;
      font-weight: 700;
    }
    h3 {
      font-size: 24px;
      color: $baseColor;
      margin: 0.25em 0 0.5em;
      @include lessMediaQuery() {
        font-size: 18px;
      }
    }
    .more {
      display: inline-block;
      padding: 0.3em 3em;
      margin-top: 20px;
      position: relative;
      @include lessMediaQuery() {
        padding: 0.5em 3em;
        display: block;
        margin: 20px auto 0;
        width: 180px;
        text-align: center;
      }
      &::before {
        @include beforeAfter(8px, 8px);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: calc(50% - 4px);
        transition: $ts;
      }
    }
  }
  .newsEntryPost {
    margin-top: 30px;
    time {
      color: $orangeColor;
      font-weight: 700;
    }
    h3 {
      font-weight: 700;
      font-size: 30px;
      color: $baseColor;
      margin: 0.2em 0 0.5em;
      padding-bottom: 0.75em;
      border-bottom: 2px solid #ccc;
      @include lessMediaQuery() {
        font-size: 18px;
      }
    }
  }
  .entry {
    > section {
      margin-top: 40px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      color: $baseColor;
      background: $lightBlueColor;
      padding: 10px 20px;
      margin-bottom: 1em;
    }
    p {
      + p {
        margin-top: 30px;
      }
    }
    img {
      height: auto;
    }
    .alignleft {
      margin: 0 40px 20px 0;
      @include lessMediaQuery() {
        float: none;
        margin: 0 auto;
      }
    }
  }
  .previousNext {
    margin-top: 80px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
  }
}

// **************************************************
//	applyContents
// **************************************************
#applyContents {
  .inner {
    max-width: 750px;
    margin-bottom: 90px;
  }
  form {
    dl {
      margin-bottom: 15px;
      @include lessMediaQuery() {
        flex-direction: column;
        align-items: center;
      }
    }
    dt {
      width: 240px;
      @include lessMediaQuery() {
        width: 100%;
      }
    }
    dd {
      flex: 1;
      @include lessMediaQuery() {
        width: 100%;
      }
    }
    input,
    textarea {
      border: 1px solid #ccc;
      padding: 0.75em;
      width: 100%;
      border-radius: 3px;
      transition: $ts;
      &:focus {
        border: 1px solid $baseColor;
      }
    }
    .ttlBlueLineTable {
      + div {
        text-align: center;
      }
    }
    .contact-btn,
    .wpcf7c-btn-back {
      width: auto;
      padding: 30px 80px;
      display: inline-block;
      background: #37357d;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      transition: $ts;
      border: none;
      cursor: pointer;
      border-radius: 0;
      margin-top: 20px;
      @include lessMediaQuery() {
        max-width: 100%;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .wpcf7-response-output {
    border-color: $vividBlueColor;
    color: $vividBlueColor;
    padding: 20px;
    background: rgba($vividBlueColor, 0.1);
  }
  .wpcf7-not-valid-tip {
    margin-top: 5px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body,
h1, h2, h3, h4, h5, h6,
a, p, span,
em, small, strong,
sub, sup,
mark, del, ins, strike,
abbr, dfn,
blockquote, q, cite,
code, pre,
ol, ul, li, dl, dt, dd,
div, section, article,
main, aside, nav,
header, hgroup, footer,
img, figure, figcaption,
address, time,
audio, video,
canvas, iframe,
details, summary,
fieldset, form, label, legend,
table, caption,
tbody, tfoot, thead,
tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-style: normal;
  line-height: 1;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
dt,
dd,
th,
td,
small,
figcaption,
time {
  line-height: 1.75;
}

img {
  display: block;
}

small,
figcaption,
time {
  display: block;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
}

input,
button,
textarea,
select {
  font: inherit;
  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
}
